import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RoleManager from './RoleManager';
import {
  getRoleFromGroupname,
  getRoleInGroupsFromSection,
  getShortenSection,
  listManagedSectionsInBo,
  listSectionsInBo,
} from '../../modules/auth';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useNav } from 'react-redux';
import {
  fetchSectionUsers,
  fetchSectionsUsers,
  savePermissionsChanges,
  resetMessage,
} from '../../actions/users';
import { Loader } from '@aws-amplify/ui-react';
import { Button } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { listManagedSections, listSections } from '../../modules/auth';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Breadcrumb } from 'back-office-common';

import './userManager.scss';
import { bo_id, subgroups } from 'bo-config';

const SectionManager = () => {
  const { section } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectionsUsers = useSelector(
    (state) => state.users.sectionsUsers || null
  );
  const message = useSelector((state) => state.users.message || null);
  const permissionsChanges = useSelector(
    (state) => state.users.permissionsChanges
  );
  //const users = [(sectionsUsers ? sectionsUsers[section] : []) || []];
  //const users = [(sectionsUsers ? sectionsUsers[section] : []) || []];

  const showActions = !(listManagedSections(Auth.user).length === 0);
  //const [isSnackVisible, setIsSnackVisible] = useState(true);
  const handleCloseSnack = (event) => {
    dispatch(resetMessage());
  };

  useEffect(() => {
    dispatch(fetchSectionsUsers(listSections(Auth.user, bo_id)));
  }, [dispatch, section]);

  if (
    !listSections(Auth.user, bo_id).some((section) =>
      listSectionsInBo(subgroups, bo_id).includes(section)
    )
  ) {
    return <ErrorPage />;
  }

  if (!sectionsUsers || Object.keys(sectionsUsers).length === 0) {
    return (
      <div className="content">
        <div style={{ width: '900px' }}>
          <Breadcrumb path={[{ text: 'Members' }]} />
          <div>
            <Loader />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content">
      <div
        className="section-manager"
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '900px',
          justifyContent: 'start',
        }}
      >
        <Breadcrumb path={[{ text: 'Members' }]} />
        {sectionsUsers &&
          Object.keys(sectionsUsers)
            .sort()
            .map((section) => (
              <ul key={section}>
                <h2 style={{ textTransform: 'capitalize' }}>
                  {getShortenSection(section, bo_id)}
                </h2>
                {sectionsUsers[section].length > 0 ? (
                  sectionsUsers[section].map((user) => {
                    return (
                      <li className="user-list" key={user.username + section}>
                        <span>{user.username}</span>
                        <RoleManager
                          disabled={!showActions}
                          username={user.username}
                          sectionName={section}
                          role={getRoleInGroupsFromSection(
                            user.groups,
                            section,
                            bo_id
                          )}
                        ></RoleManager>
                      </li>
                    );
                  })
                ) : (
                  <li className="user-list" key={section + 'empty'}>
                    No members
                  </li>
                )}
              </ul>
            ))}
        {showActions && (
          <div className="actions">
            <Button
              size="small"
              className="button-secondary"
              onClick={() => navigate('/members/')}
            >
              Add member
            </Button>
            <Button
              size="small"
              className="button-primary"
              onClick={() => dispatch(savePermissionsChanges())}
              disabled={!permissionsChanges || permissionsChanges.length === 0}
            >
              Valider
            </Button>
          </div>
        )}
        {message.text && (
          <Snackbar
            open={!!message.text}
            autoHideDuration={1500}
            onClose={handleCloseSnack}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            {message.type === 'success' ? (
              <MuiAlert variant="filled" severity="success">
                {message.text}
              </MuiAlert>
            ) : (
              <MuiAlert variant="filled" severity="error">
                {message.text}
              </MuiAlert>
            )}
          </Snackbar>
        )}
      </div>
    </div>
  );
};

SectionManager.propTypes = {};

export default SectionManager;
