import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { Auth, API } from 'aws-amplify';

import notifications from '../middlewares/notifications';
import reducer from '../reducers';

const composeEnhancers = composeWithDevTools({});

const enhancer = (services) =>
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(services), notifications)
  );

export const configureStore = () =>
  createStore(reducer, enhancer({ Auth, API }));

export const configureInMemoryStore = (inMemoryAuth, inMemoryAPI) =>
  createStore(reducer, enhancer({ Auth: inMemoryAuth, API: inMemoryAPI }));
