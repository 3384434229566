export const formatDate = (string) => {
  const date = new Date(string);
  return `${date.getUTCFullYear()}-${
    date.getUTCMonth() > 8
      ? date.getUTCMonth() + 1
      : '0' + (date.getUTCMonth() + 1)
  }-${
    date.getUTCDate() > 8
      ? date.getUTCDate() + 1
      : '0' + (date.getUTCDate() + 1)
  }`;
};
