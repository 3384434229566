import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import { BrowserRouter as Router } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { Provider } from 'react-redux';
import { configureStore } from './store';

const store = configureStore();
Amplify.configure(awsExports);

/* const REGEX = /.*-(\w+)/;
const env = awsExports.aws_user_files_s3_bucket.match(REGEX)[1];
const title = document.querySelector('title');
title.innerHTML = `${env} - Backoffice`;
 */

try {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
} catch (err) {
  console.log(err);
}
