/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendNotification = /* GraphQL */ `
  mutation SendNotification($notificationId: ID!, $testing: Boolean) {
    sendNotification(notificationId: $notificationId, testing: $testing)
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      topics
      name
      forceEnglish
      translations {
        nextToken
      }
      sendingRequests {
        nextToken
      }
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationJobNotificationId
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      topics
      name
      forceEnglish
      translations {
        nextToken
      }
      sendingRequests {
        nextToken
      }
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationJobNotificationId
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      topics
      name
      forceEnglish
      translations {
        nextToken
      }
      sendingRequests {
        nextToken
      }
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationJobNotificationId
    }
  }
`;
export const createTranslation = /* GraphQL */ `
  mutation CreateTranslation(
    $input: CreateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    createTranslation(input: $input, condition: $condition) {
      id
      title
      description
      link
      lang
      messageId
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      createdAt
      updatedAt
      notificationTranslationsId
    }
  }
`;
export const updateTranslation = /* GraphQL */ `
  mutation UpdateTranslation(
    $input: UpdateTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    updateTranslation(input: $input, condition: $condition) {
      id
      title
      description
      link
      lang
      messageId
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      createdAt
      updatedAt
      notificationTranslationsId
    }
  }
`;
export const deleteTranslation = /* GraphQL */ `
  mutation DeleteTranslation(
    $input: DeleteTranslationInput!
    $condition: ModelTranslationConditionInput
  ) {
    deleteTranslation(input: $input, condition: $condition) {
      id
      title
      description
      link
      lang
      messageId
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      createdAt
      updatedAt
      notificationTranslationsId
    }
  }
`;
export const createSendingRequest = /* GraphQL */ `
  mutation CreateSendingRequest(
    $input: CreateSendingRequestInput!
    $condition: ModelSendingRequestConditionInput
  ) {
    createSendingRequest(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      topics
      lang
      status
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationSendingRequestsId
      jobNotificationSendingRequestsId
      sendingRequestJobNotificationId
    }
  }
`;
export const updateSendingRequest = /* GraphQL */ `
  mutation UpdateSendingRequest(
    $input: UpdateSendingRequestInput!
    $condition: ModelSendingRequestConditionInput
  ) {
    updateSendingRequest(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      topics
      lang
      status
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationSendingRequestsId
      jobNotificationSendingRequestsId
      sendingRequestJobNotificationId
    }
  }
`;
export const deleteSendingRequest = /* GraphQL */ `
  mutation DeleteSendingRequest(
    $input: DeleteSendingRequestInput!
    $condition: ModelSendingRequestConditionInput
  ) {
    deleteSendingRequest(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      topics
      lang
      status
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationSendingRequestsId
      jobNotificationSendingRequestsId
      sendingRequestJobNotificationId
    }
  }
`;
export const createJobNotification = /* GraphQL */ `
  mutation CreateJobNotification(
    $input: CreateJobNotificationInput!
    $condition: ModelJobNotificationConditionInput
  ) {
    createJobNotification(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      status
      sendingRequests {
        nextToken
      }
      createdAt
      updatedAt
      jobNotificationNotificationId
    }
  }
`;
export const updateJobNotification = /* GraphQL */ `
  mutation UpdateJobNotification(
    $input: UpdateJobNotificationInput!
    $condition: ModelJobNotificationConditionInput
  ) {
    updateJobNotification(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      status
      sendingRequests {
        nextToken
      }
      createdAt
      updatedAt
      jobNotificationNotificationId
    }
  }
`;
export const deleteJobNotification = /* GraphQL */ `
  mutation DeleteJobNotification(
    $input: DeleteJobNotificationInput!
    $condition: ModelJobNotificationConditionInput
  ) {
    deleteJobNotification(input: $input, condition: $condition) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      status
      sendingRequests {
        nextToken
      }
      createdAt
      updatedAt
      jobNotificationNotificationId
    }
  }
`;
