import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useSelector, useDispatch } from 'react-redux';
import UserGroupRoleManager from './UserGroupRoleManager';
import RoleManager from './RoleManager';
import {
  getRoleInGroupsFromSection,
  listManagedSections,
  listManagedSectionsInBo,
} from '../../modules/auth';
import { Button, Loader } from '@aws-amplify/ui-react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { savePermissionsChanges, resetMessage } from '../../actions/users';

const UserGroupManager = () => {
  const message = useSelector((state) => state.users.message || null);
  const bo_id = useSelector((state) => state.users.bo_id);
  const sections = listManagedSectionsInBo(Auth.user, bo_id);
  const permissionsChanges = useSelector(
    (state) => state.users.user.permissionsChanges
  );
  const groups = useSelector((state) => state.users.user.groups);

  const dispatch = useDispatch();
  const [isSnackVisible, setIsSnackVisible] = useState(false);

  const handleCloseSnack = (event) => {
    console.log('close');

    //setIsSnackVisible(false);
    dispatch(resetMessage());
  };

  if (!sections || !groups) {
    return <Loader />;
  }

  return (
    <div>
      {sections.map((section) => {
        return (
          <UserGroupRoleManager
            role={getRoleInGroupsFromSection(groups, section, bo_id) || ''}
            sectionName={section}
            key={section}
          ></UserGroupRoleManager>
        );
      })}
      <div className="actions">
        <Button
          size="small"
          onClick={() => dispatch(savePermissionsChanges())}
          disabled={!permissionsChanges || permissionsChanges.length === 0}
        >
          Valider
        </Button>
      </div>
      {message.text && (
        <Snackbar
          open={!!message.text}
          autoHideDuration={1500}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          {message.type === 'success' ? (
            <MuiAlert variant="filled" severity="success">
              {message.text}
            </MuiAlert>
          ) : (
            <MuiAlert variant="filled" severity="error">
              {message.text}
            </MuiAlert>
          )}
        </Snackbar>
      )}
    </div>
  );
};

UserGroupManager.propTypes = {};

export default UserGroupManager;
