import { Link, useParams } from 'react-router-dom';
import './errorpage.scss';

const ErrorPage = (props) => {
  const { code } = useParams();
  return (
    <div className="error-page">
      <p className="error-title">{code || '404'}</p>
      <p className="error-content">Are you lost ?</p>
      <Link to="/">Return to the homepage</Link>
    </div>
  );
};

export default ErrorPage;
