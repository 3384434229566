export const SET_USER = 'SET_USER';
export const RESET = 'RESET';

export const setUser = (user) => ({
  type: SET_USER,
  user,
});

export const reset = () => ({
  type: RESET,
});
