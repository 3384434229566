import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, SelectField } from '@aws-amplify/ui-react';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRole } from '../../actions/users';
import { upsertPermissionChange } from '../../actions/users';
import { getGroupname, getShortenSection } from '../../modules/auth';
import RoleManager from './RoleManager';

const options = ['Owner', 'Manager', 'Contributor', 'Viewer'];

const UserGroupRoleManager = ({ sectionName, role }) => {
  const [value, setValue] = React.useState(role);
  const bo_id = useSelector((state) => state.users.bo_id);
  const dispatch = useDispatch();

  const filteredOptions = options.filter(
    (option) => option !== 'Owner' || role === 'owner'
  );

  const title = getShortenSection(sectionName, bo_id);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'space-between',
      }}
    >
      <span style={{ textTransform: 'capitalize' }}>
        {bo_id === title ? title : bo_id + '-' + title}
      </span>
      <RoleManager role={role} sectionName={sectionName} />
    </div>
  );
};

export default UserGroupRoleManager;
