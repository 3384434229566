import { getGroups } from 'back-office-common/modules/auth';
import {
  getGroupname,
  getRoleFromGroupname,
  getSectionFromGroupname,
} from '../modules/auth';
import { formatDate } from '../modules/date';
import { bo_id } from 'bo-config';

export const SET_SEARCHED_USER = 'SET_SEARCHED_USER';
export const RESET_USER = 'RESET_USER';
export const SET_SEARCHING = 'SET_SEARCHING';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_GROUP_USERS = 'SET_SELECTED_GROUP_USERS';
export const ADD_USER_TO_GROUP = 'ADD_SELECTED_USER_TO_GROUP';
export const REMOVE_USER_FROM_GROUP = 'REMOVE_SELECTED_USER_FROM_GROUP';
export const SET_MESSAGE = 'SET_MESSAGE';
export const RESET_MESSAGE = 'RESET_MESSAGE';
export const UPSERT_PERMISSIONS_CHANGES = 'UPSERT_PERMISSIONS_CHANGES';
export const SET_USER_PERMISSIONS_CHANGES = 'SET_USER_PERMISSIONS_CHANGES';
export const SET_PERMISSIONS_CHANGES = 'SET_PERMISSIONS_CHANGES';
export const RESET_USER_PERMISSIONS_CHANGES = 'RESET_USER_PERMISSIONS_CHANGES';
export const RESET_PERMISSIONS_CHANGES = 'RESET_PERMISSIONS_CHANGES';
export const SET_SECTION_USERS = 'SET_SECTION_USERS';
export const SET_SECTION_USER_ROLE = 'SET_SECTION_USER_ROLE';

export const DELETE_SECTION_USER_ROLE = 'DELETE_SECTION_USER_ROLE';

const options = ['Owner', 'Manager', 'Contributor', 'Viewer'];

const apiName = 'UserManager';
const getMyInit = async (Auth, queryStringParameters, body) => {
  const myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  if (body) {
    myInit.body = body;
  } else {
    myInit.queryStringParameters = queryStringParameters;
  }
  return myInit;
};

export const fetchUser = (email) => async (dispatch, getState, services) => {
  try {
    dispatch(resetUser());
    const user = await services.API.get(
      apiName,
      '/getUser',
      await getMyInit(services.Auth, { username: email })
    );
    const userAttributes = user.UserAttributes.reduce((object, attribute) => {
      object = { ...object, [attribute.Name]: attribute.Value };
      return object;
    }, {});
    const formattedUser = { ...user, ...userAttributes };
    delete formattedUser.UserAttributes;
    formattedUser.createdAt = formatDate(formattedUser.UserCreateDate);
    formattedUser.updatedAt = formatDate(formattedUser.UserLastModifiedDate);
    delete formattedUser.UserCreateDate;
    delete formattedUser.UserLastModifiedDate;
    dispatch(setUser(formattedUser));
    dispatch(fetchUserGroups(email));
  } catch (error) {
    console.log(error);
    dispatch(setSearching(false));
  }
};

export const setUser = (user) => ({
  type: SET_SEARCHED_USER,
  user,
});

export const resetUser = () => ({
  type: RESET_USER,
});

export const setSearching = (value) => ({
  type: SET_SEARCHING,
  value,
});

export const fetchUserGroups =
  (email) => async (dispatch, getState, services) => {
    try {
      const { Groups: groups } = await services.API.get(
        apiName,
        '/listGroupsforUser',
        await getMyInit(services.Auth, { username: email })
      );
      dispatch(setUserGroups(groups.map((group) => group.GroupName)));
    } catch (error) {
      console.log(error);
    }
  };

export const setUserGroups = (groups) => ({
  type: SET_USER_GROUPS,
  groups,
});

export const addUserToGroup = (user, groupname) => ({
  type: ADD_USER_TO_GROUP,
  user,
  groupname,
});

export const removeUserFromGroup = (user, groupname) => ({
  type: REMOVE_USER_FROM_GROUP,
  groupname,
});

export const fetchSectionUsers =
  (section, dispatch, services) => async (dispatch, getState, services) => {
    const promises = [];
    let newSectionsUsers = {};
    try {
      for (const option of options) {
        const promise = fetchGroupUsers(
          getGroupname(section, option.toLowerCase(), bo_id),
          services
        );
        promises.push(promise);
      }
      newSectionsUsers[section] = (await Promise.allSettled(promises)).reduce(
        (newSectionUsers, groupUsers) => {
          return newSectionUsers.concat(groupUsers.value);
        },
        []
      );

      newSectionsUsers[section] = newSectionsUsers[section].filter(
        (user) => user
      );
      /* .filter((user) => user.status === 'fulfilled' && user.value[0])
        .map((promiseResult) => promiseResult.value[0]); */
      dispatch(setSectionUsers(newSectionsUsers));
    } catch (error) {
      console.log(error);
      dispatch(
        setMessage({
          text: 'Error while fetching users' + Math.random(),
          type: 'error',
        })
      );
      dispatch(
        setSectionUsers(
          newSectionsUsers[section].filter(
            (user) => user.username || user.status === 'fulfilled'
          )
        )
      );
    }
  };

export const fetchSectionsUsers =
  (sections) => async (dispatch, getState, services) => {
    for (const section of sections) {
      await dispatch(fetchSectionUsers(section, dispatch, services));
    }
  };

export const fetchGroupUsers = async (groupname, services) => {
  try {
    const { Users: users } = await services.API.get(
      apiName,
      '/listUsersInGroup',
      await getMyInit(services.Auth, { groupname })
    );
    return users
      .map((user) => ({
        username: user.Attributes.find((attr) => attr.Name === 'email').Value,
        groups: [groupname],
      }))
      .sort((a, b) => a.username > b.username);
  } catch (error) {
    //console.log(error);
  }
};

export const setSelectedGroupUsers = (users) => ({
  type: SET_GROUP_USERS,
  users,
});

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  message,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
});

export const upsertPermissionChange =
  (permissionChange) => (dispatch, getState) => {
    const permissionsChanges =
      (!permissionChange.username
        ? getState().users.user?.permissionsChanges
        : getState().users.permissionsChanges) || [];
    const newPermissionsChanges = [...permissionsChanges];
    const index = permissionsChanges.findIndex((element) => {
      return (
        element.previousRole === permissionChange.previousRole &&
        element.sectionName === permissionChange.sectionName &&
        element?.username === permissionChange?.username
      );
    });
    if (index === -1) newPermissionsChanges.push(permissionChange);
    else if (permissionChange.previousRole === permissionChange.newRole)
      newPermissionsChanges.splice(index, 1);
    else newPermissionsChanges.splice(index, 1, permissionChange);
    if (permissionChange.username) {
      dispatch(setPermissionsChanges(newPermissionsChanges));
    } else {
      dispatch(setUserPermissionsChanges(newPermissionsChanges));
    }
  };

export const setUserPermissionsChanges = (permissionsChanges) => ({
  type: SET_USER_PERMISSIONS_CHANGES,
  permissionsChanges,
});

export const setPermissionsChanges = (permissionsChanges) => ({
  type: SET_PERMISSIONS_CHANGES,
  permissionsChanges,
});

export const setSectionUsers = (users) => ({
  type: SET_SECTION_USERS,
  users,
});

export const setSectionUserRole = (username, section, role) => ({
  type: SET_SECTION_USER_ROLE,
  username,
  section,
  role,
});

export const resetUserPermissionsChanges = () => ({
  type: RESET_USER_PERMISSIONS_CHANGES,
});

export const resetPermissionsChanges = () => ({
  type: RESET_PERMISSIONS_CHANGES,
});

export const savePermissionsChanges =
  () => async (dispatch, getState, services) => {
    const user = getState().users.user;
    const permissionsChanges =
      getState().users.permissionsChanges || user.permissionsChanges;

    let groups = user?.groups || [];
    let newGroups = [];
    try {
      for (const permissionChange of permissionsChanges) {
        await savePermissionChange(permissionChange, services, user?.Username);
        if (user) {
          newGroups = groups.filter(
            (group) =>
              !group.includes(
                permissionChange.sectionName + '_' + permissionChange.newRole
              )
          );
          newGroups.push(
            getGroupname(permissionChange.sectionName, permissionChange.newRole)
          );
        } else {
          dispatch(
            setSectionUserRole(
              permissionChange.username,
              permissionChange.sectionName,
              permissionChange.newRole
            )
          );
        }
      }
      if (user) {
        dispatch(setUserGroups(newGroups));
        dispatch(resetUserPermissionsChanges());
      } else {
        dispatch(resetPermissionsChanges());
      }
      dispatch(
        setMessage({ text: 'Permissions changes saved', type: 'success' })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setMessage({ text: 'An error occured while saving', type: 'error' })
      );
    }
  };

const savePermissionChange = async (permissionChange, services, username) => {
  if (permissionChange.newRole) {
    const addResponse = await services.API.post(
      apiName,
      '/addUserToGroup',
      await getMyInit(services.Auth, null, {
        username: username || permissionChange.username,
        groupname: getGroupname(
          permissionChange.sectionName,
          permissionChange.newRole,
          bo_id
        ),
      })
    );
  }

  if (permissionChange.previousRole) {
    const removeResponse = await services.API.post(
      apiName,
      '/removeUserFromGroup',
      await getMyInit(services.Auth, null, {
        username: username || permissionChange.username,
        groupname: getGroupname(
          permissionChange.sectionName,
          permissionChange.previousRole,
          bo_id
        ),
      })
    );
  }
};

export const deleteRole =
  (groupname, username) => async (dispatch, getState, services) => {
    const user = getState().users.user;
    const userName = username || user.Username;

    if (!username) {
      groupname = user.groups.find((group) =>
        group.includes(getSectionFromGroupname(groupname))
      );
    }

    try {
      const response = await services.API.post(
        apiName,
        '/removeUserFromGroup',
        await getMyInit(services.Auth, null, {
          username: userName,
          groupname,
        })
      );
      if (!username) {
        const groups = user.groups;
        const newGroups = groups.filter((group) => group !== groupname);
        dispatch(setUserGroups(newGroups));
      } else {
        dispatch(
          deleteSectionUserRole(
            userName,
            getSectionFromGroupname(groupname),
            getRoleFromGroupname(groupname)
          )
        );
      }
      dispatch(
        setMessage({ text: 'User removed from the group', type: 'success' })
      );
    } catch (error) {
      console.log(error);
      dispatch(
        setMessage({ text: 'An error occured while saving', type: 'error' })
      );
    }
  };

export const deleteSectionUserRole = (username, section) => ({
  type: DELETE_SECTION_USER_ROLE,
  username,
  section,
});
