import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { upsertPermissionChange } from '../../actions/users';
import { Button, SelectField } from '@aws-amplify/ui-react';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteRole } from '../../actions/users';
import { getGroupname } from '../../modules/auth';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { bo_id } from 'bo-config';

const options = ['Owner', 'Manager', 'Contributor', 'Viewer'];

const RoleManager = ({ role, sectionName, username = null, disabled }) => {
  const [value, setValue] = useState(role);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const filteredOptions = options.filter(
    (option) => option !== 'Owner' || role === 'owner'
  );

  useEffect(() => {
    setValue(role);
  }, [role]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    dispatch(deleteRole(getGroupname(sectionName, role, bo_id), username));
    setOpen(false);
  };

  return (
    <div style={{ display: 'flex', gap: '0.5rem' }}>
      <SelectField
        size="small"
        placeholder={!role ? 'Select a role...' : undefined}
        value={value}
        onChange={(e) => {
          dispatch(
            upsertPermissionChange({
              ...(username && { username }),
              sectionName,
              previousRole: role,
              newRole: e.target.value,
            })
          );
          setValue(e.target.value);
        }}
        disabled={disabled || role === 'owner'}
      >
        {filteredOptions.map((option) => (
          <option value={option.toLowerCase()} key={sectionName + option}>
            {option}
          </option>
        ))}
      </SelectField>
      <span style={{ display: 'flex', alignItems: 'center', width: '1.5rem' }}>
        {!disabled && role && role !== 'owner' && (
          <DeleteIcon onClick={handleClickOpen}></DeleteIcon>
        )}
      </span>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>You are about to delete this role.</DialogContent>
        <DialogActions>
          <Button size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" onClick={handleConfirm} variation={'primary'}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

RoleManager.propTypes = {};

export default RoleManager;
