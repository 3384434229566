import { useEffect, useState } from 'react';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { ReactComponent as Logotype } from '../../assets/Logotype.svg';
import { Link, useNavigate } from 'react-router-dom';

import './styles.scss';
import { useDispatch } from 'react-redux';
import { setUser, reset } from 'actions/app';
import { bo_id } from 'bo-config';

const Header = ({ signOut, user }) => {
  /*  const [isBurgerOpened, setIsBurgerOpened] = useState(false); */

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    navigate('/');
    dispatch(reset());
    signOut();
  };

  useEffect(() => {
    dispatch(setUser(user));
  }, [user, dispatch]);

  return (
    <header className="header">
      {/* <MenuIcon
        className={classNames('burger', { 'burger--clicked': isBurgerOpened })}
        onClick={() => {
          setIsBurgerOpened(!isBurgerOpened);
        }}
      ></MenuIcon> */}
      <div className="brand">
        <Logo />
        <Logotype />
        <span className="slash">
          /{' '}
          <Link className="bo-title" to="/">
            Notification manager V1.0.0
          </Link>
        </span>
      </div>

      {/* <Nav
        className={classNames({ 'mobile-hide': !isBurgerOpened })}
        user={Auth.user}
      ></Nav> */}

      <span
        style={{
          display: 'flex',
          justifyContent: 'end',
          flex: 1,
          textDecoration: 'none',
          gap: 5,
        }}
      >
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <span className="members">Notifications</span>
        </Link>
        <Link to={'/members/' + bo_id} style={{ textDecoration: 'none' }}>
          <span className="members">Members</span>
        </Link>
      </span>
      <span className="signout" onClick={handleSignOut}>
        {`Signout`}
      </span>
    </header>
  );
};

export default Header;
