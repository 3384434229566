import { Suspense, lazy } from 'react';
import { Auth } from 'aws-amplify';
import { CustomAuthenticator } from 'back-office-common/components/Authenticator/Authenticator';
import { Route, Routes, Outlet } from 'react-router-dom';
import { Loader } from '@aws-amplify/ui-react';

import Header from './components/Header';
import ErrorPage from './components/ErrorPage/ErrorPage';
import './App.scss';
import '@aws-amplify/ui-react/styles.css';

import { bo_id } from './bo-config';
import { checkGroup, getGroups } from 'back-office-common';
import SectionManager from 'components/UserManager/SectionManager';
import UserManager from 'components/UserManager/UserManager';

// Lazy loading to optimize the loading time
const ArticleForm = lazy(async () =>
  import('./components/Article/ArticleForm')
);
const ArticlesList = lazy(async () => import('./components/ArticlesList'));
const NotificationForm = lazy(async () =>
  import('./components/NotificationForm/NotificationForm')
);
const NotificationsList = lazy(async () =>
  import('./components/NotificationsList/NotificationsList')
);

const routesList = {
  bo_content_article_routes: [
    <Route
      path="articles"
      key="articles"
      element={
        <Suspense
          fallback={
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                paddingTop: '100px',
              }}
            >
              <Loader />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      }
    >
      <Route path="add" element={<ArticleForm></ArticleForm>} />
      <Route path="list" element={<ArticlesList></ArticlesList>} />
      <Route index element={<ArticlesList></ArticlesList>} />
    </Route>,
    <Route
      path="notifications"
      key="notifications"
      element={
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      }
    >
      <Route path="add" element={<NotificationForm></NotificationForm>} />
      {/*       <Route path="last" element={<NotificationsList></NotificationsList>} />
       */}{' '}
      <Route index element={<NotificationsList></NotificationsList>} />
    </Route>,
  ],

  bo_content_notif_routes: [
    <Route
      path="notifications"
      key="notifications"
      element={
        <Suspense fallback={<Loader />}>
          <>
            <div className="content">
              <Outlet />
            </div>
          </>
        </Suspense>
      }
    >
      {/*     <Route path="add" element={<NotificationForm></NotificationForm>} />
      <Route path="last" element={<NotificationsList></NotificationsList>} />
      <Route
        path="members"
        element={<MembersPage tab={'Users'}></MembersPage>}
      /> */}
      <Route index element={<NotificationForm></NotificationForm>} />
    </Route>,
  ],
  bonotification_routes: [
    <Route
      path="notifications"
      key="notifications"
      element={
        <Suspense fallback={<Loader />}>
          <>
            <div className="content">
              <Outlet />
            </div>
          </>
        </Suspense>
      }
    >
      {/*     <Route path="add" element={<NotificationForm></NotificationForm>} />
      <Route path="last" element={<NotificationsList></NotificationsList>} />*/}

      <Route index element={<NotificationForm></NotificationForm>} />
    </Route>,
    <Route path="members" element={<Outlet></Outlet>} key="members">
      <Route path=":section" element={<SectionManager></SectionManager>} />
      <Route index element={<UserManager />} />
    </Route>,
  ],
  adminapi_routes: [
    <Route
      path="notifications"
      key="notifications"
      element={
        <Suspense fallback={<Loader />}>
          <>
            <div className="content">
              <Outlet />
            </div>
          </>
        </Suspense>
      }
    >
      {/*     <Route path="add" element={<NotificationForm></NotificationForm>} />
      <Route path="last" element={<NotificationsList></NotificationsList>} />*/}

      <Route index element={<NotificationForm></NotificationForm>} />
    </Route>,
    <Route path="members" element={<Outlet></Outlet>} key="members">
      <Route path=":section" element={<SectionManager></SectionManager>} />
      <Route index element={<UserManager />} />
    </Route>,
  ],
  common_routes: [
    <Route
      path="/"
      element={
        <Suspense fallback={<Loader />}>
          <NotificationsList />
        </Suspense>
      }
      key="home"
    />,
    <Route path="*" element={<ErrorPage></ErrorPage>} key="error"></Route>,
  ],
};

const getRoutes = (groups) => {
  let routes = routesList.common_routes;
  const tabs = [
    'bo_content_article',
    'bo_content_notif',
    'bonotification',
    'adminapi',
  ];
  for (const tab of tabs) {
    if (groups?.find((item) => item.includes(tab)))
      routes = routesList[`${tab}_routes`]?.concat(routes) || routes;
  }
  return routes;
};

function App() {
  return (
    <div className="App">
      <CustomAuthenticator auth={Auth} bo_id={bo_id} checkGroup={checkGroup}>
        {({ signOut, user }) => {
          const groups = getGroups(Auth.user);
          const routes = getRoutes(groups);

          return (
            <>
              <Header signOut={signOut} user={user}></Header>
              <div className="main">
                <Routes>{routes}</Routes>
              </div>
            </>
          );
        }}
      </CustomAuthenticator>
    </div>
  );
}

export default App;
