import {
  SET_CURRENT_CONTENT,
  SET_CURRENT_CONTENT_LANGUAGE,
  SET_CURRENT_CONTENT_INDEX,
  SET_CURRENT_NOTIFICATION,
  SET_NOTIFICATIONS,
  ADD_NOTIFICATION,
  ADD_TRANSLATION,
  SET_IS_LOADING,
  SET_IS_SAVING,
  SET_MESSAGE,
  RESET_MESSAGE,
  SET_NEXT_TOKEN,
  SET_IS_SENDING,
} from '../actions/notifications';

import { Translation } from 'Model/notification';

const initialState = {
  notifications: [],
  currentNotification: {
    translations: [{ lang: 'lg_en' }],
    segmentation: {
      device: {
        iOS: true,
        android: true,
      },
      topics: [],
    },
  },
  currentContent: { index: 0 },
  isLoading: true,
  isSaving: false,
  isSending: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.payload,
      };
    case SET_IS_SENDING:
      return {
        ...state,
        isSending: action.payload,
      };
    case SET_NEXT_TOKEN:
      return {
        ...state,
        nextToken: action.nextToken,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: null,
      };
    case SET_CURRENT_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.currentNotification,
      };
    case SET_CURRENT_CONTENT:
      return {
        ...state,
        currentContent: new Translation(action.currentContent),
      };
    case SET_CURRENT_CONTENT_LANGUAGE:
      return {
        ...state,
        currentContent: { ...state.currentContent, lang: action.language },
      };
    case SET_CURRENT_CONTENT_INDEX:
      return {
        ...state,
        currentContent: { ...state.currentContent, index: action.index },
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        isLoading: false,
        isSaving: false,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.notification, ...state.notifications],
        isSaving: false,
      };
    case ADD_TRANSLATION:
      return {
        ...state,
        currentNotification: {
          ...state.currentNotification,
          translations: [
            ...state.currentNotification.translations,
            new Translation({
              lang: action.language,
              updated: true,
              title: '',
              description: '',
            }),
          ],
        },
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
