import { bo_id, GROUP_ADMIN, subgroups } from '../bo-config';
import { getGroups } from 'back-office-common/modules/auth';

const roles = ['owner', 'manager', 'contributor', 'viewer'];

export const getAdminStatus = (user) => {
  return user?.signInUserSession?.accessToken?.payload[
    'cognito:groups'
  ]?.includes(GROUP_ADMIN);
};

/**
 * Function that get the role of the user in the current bo
 * @param {user} cognito user
 * @param {string} bo_id
 * @returns {string|undefined} The role of the user in the current bo
 */
export const getRole = (user, bo_id) => {
  const group = getGroupInBo(user, bo_id);
  if (getAdminStatus(user)) return GROUP_ADMIN;
  const roleAndSubgroup = group?.split(bo_id)[1];
  if (!roleAndSubgroup) {
    return undefined;
  }
  return roles.find((role) => roleAndSubgroup.includes(role));
};

/**
 * Function that get the group of the user in the current bo
 * @param {user} cognito user
 * @param {string} bo_id
 * @returns {string|undefined} The group of the user in the current bo
 */
export const getGroupInBo = (user, bo_id) => {
  const groups = getGroups(user);
  return groups?.find((group) => group.includes(bo_id));
};

/**
 * Function that retrieves the list of managing groups the user belongs to
 * @param {user} cognito user
 * @param {string} bo_id
 * @returns {string[]} The list of managing groups the user belongs to
 */
export const listManagedGroups = (user) => {
  return getGroups(user).filter(
    (group) => group.includes('owner') || group.includes('manager')
  );
};

/**
 * Function that retrieves the list of managed sections by the user
 * @param {user} cognito user
 * @param {string} bo_id
 * @returns {string[]} The list of managed sections by the user
 */
export const listManagedSections = (user, bo_id) => {
  const groups = getGroups(user);
  return isGlobalManager(user)
    ? listSectionsInBo(subgroups, bo_id)
    : listManagedGroups(user).map((group) => getSectionFromGroupname(group)) ||
        [];
};

export const listManagedSectionsInBo = (user, bo_id) => {
  const groups = getGroups(user);
  const boSections = listSectionsInBo(subgroups, bo_id);
  return isGlobalManager(user)
    ? boSections
    : listManagedSections(user, bo_id).filter((section) =>
        boSections.includes(section)
      );
};

export const listSectionsInBo = (subgroups, bo_id) => {
  return [bo_id, ...subgroups.map((subgroup) => bo_id + subgroup)];
};

export const listSections = (user, bo_id) => {
  const groups = getGroups(user);
  return isGlobalManager(user)
    ? listSectionsInBo(subgroups, bo_id)
    : groups
        .filter((group) => group.includes('bo' + bo_id))
        .map((group) => getSectionFromGroupname(group)) || [];
};

export const getRoleInGroupsFromSection = (groupNames, section, bo_id) => {
  let groupNamesFromSection = [];
  if (section === bo_id) {
    groupNamesFromSection = groupNames.filter((group) => {
      const role = getRoleFromGroupname(group);
      return group.replace('bo' + bo_id, '') === role;
    });
  } else {
    groupNamesFromSection = groupNames.filter((group) =>
      group.includes(section.replace(bo_id, ''))
    );
  }
  if (groupNamesFromSection.length === 1) {
    return getRoleFromGroupname(groupNamesFromSection[0] || '');
  } else {
    return roles[
      Math.min(
        ...groupNamesFromSection.map((group) =>
          roles.indexOf(getRoleFromGroupname(group))
        )
      )
    ];
  }
};

export const getSectionFromGroupname = (groupName) => {
  const splittedGroupName = groupName.split(getRoleFromGroupname(groupName));
  const subgroup = splittedGroupName[1] || '';
  const bo_id = splittedGroupName[0].replace('bo', '');

  return bo_id + subgroup;
};

export const getShortenSection = (section, bo_id) => {
  return section.replace(bo_id, '') || section;
};

export const getRoleFromGroupname = (groupName) => {
  return roles.find((role) => groupName.includes(role));
};

export const getGroupname = (sectionName, role, bo_id) => {
  if (bo_id === sectionName) return 'bo' + bo_id + role;
  return 'bo' + bo_id + role + sectionName.replace(bo_id, '');
};

/**
 * check if the user is a manager of whole bo (subgroups included)
 * @param {*} cognito user
 * @returns {boolean} true if the user is a global manager
 */
export const isGlobalManager = (user) => {
  const groups = getGroups(user);
  return (
    groups.includes(GROUP_ADMIN) ||
    groups.includes('bo' + bo_id + 'owner') ||
    groups.includes('bo' + bo_id + 'manager')
  );
};
