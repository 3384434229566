import {
  SET_SEARCHED_USER,
  RESET_USER,
  SET_USER_GROUPS,
  SET_GROUP_USERS,
  SET_SEARCHING,
  SET_MESSAGE,
  RESET_MESSAGE,
  SET_GROUPS,
  SET_USER_PERMISSIONS_CHANGES,
  SET_PERMISSIONS_CHANGES,
  RESET_PERMISSIONS_CHANGES,
  RESET_USER_PERMISSIONS_CHANGES,
  SET_SECTION_USERS,
  SET_SECTION_USER_ROLE,
  DELETE_SECTION_USER_ROLE,
} from '../actions/users';

import { RESET } from '../actions/app';
import { getGroupname } from '../modules/auth';
import { bo_id } from '../bo-config';

const initialState = {
  bo_id,
  users: [],
  nextToken: null,
  selectedUser: {},
  groups: [],
  message: {
    text: '',
    type: '',
  },
  selectedGroup: {
    users: [],
  },
  filters: {},
  isSearching: false,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SEARCHED_USER:
      return {
        ...state,
        user: action.user ? { ...action.user } : null,
        isSearching: false,
      };
    case RESET_USER:
      return {
        ...state,
        user: null,
      };
    case SET_USER_GROUPS:
      return {
        ...state,
        user: { ...state.user, groups: [...action.groups] },
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: { ...action.groups },
      };
    case SET_SEARCHING:
      return {
        ...state,
        isSearching: action.value,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: initialState.message,
      };
    case SET_PERMISSIONS_CHANGES:
      return {
        ...state,
        permissionsChanges: [...action.permissionsChanges],
      };
    case SET_USER_PERMISSIONS_CHANGES:
      return {
        ...state,
        user: {
          ...state.user,
          permissionsChanges: [...action.permissionsChanges],
        },
      };
    case RESET_PERMISSIONS_CHANGES:
      return {
        ...state,
        permissionsChanges: undefined,
      };
    case RESET_USER_PERMISSIONS_CHANGES:
      return {
        ...state,
        user: {
          ...state.user,
          permissionsChanges: undefined,
        },
      };
    case SET_SECTION_USERS:
      return {
        ...state,
        sectionsUsers: {
          ...state.sectionsUsers,
          ...action.users,
        },
      };
    case SET_SECTION_USER_ROLE:
      return {
        ...state,
        sectionsUsers: {
          ...state.sectionsUsers,
          [action.section]: [
            ...state.sectionsUsers[action.section].map((user) => {
              if (user.username === action.username) {
                return {
                  ...user,
                  groups: [
                    getGroupname(action.section, action.role, state.bo_id),
                  ],
                };
              } else {
                return user;
              }
            }),
          ],
        },
      };
    case DELETE_SECTION_USER_ROLE:
      return {
        ...state,
        sectionsUsers: {
          ...state.sectionsUsers,
          [action.section]: [
            ...state.sectionsUsers[action.section].filter(
              (user) => user.username !== action.username
            ),
          ],
        },
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
