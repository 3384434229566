import { 
    SET_CURRENT_ARTICLE,
} from "../actions/articles";

const initialState = {
    currentArticle : [
        {
            header:{
                title:"",
                description:"",
                image: "",
            },
            sections : [],
            info : {
                "articleID": "stringID",
                "startDate": "start date of visibility",
                "endDate": "end date of visibility",
                "version": 1,
                "sharingUrl": "sharing URL",
                "keywords": ["keyword1", "keywords2"],
                "category": 0,
                "isDraft": true,
                "countryCode": "fr"
            },
        }
        /* {
            "header": {
                "title": "title of the article",
                "description": "Duis rhoncus libero ac lectus blandit rhoncus. Integer elementum tincidunt est, nec mollis mauris varius vel. Morbi orci velit, dignissim non sollicitudin quis, eleifend quis eros. Vivamus dignissim venenatis lorem id euismod. Mauris porta ex sed lectus bibendum, in interdum velit fringilla. Duis facilisis sodales massa, quis placerat nibh elementum egestas. Fusce eleifend ante eu bibendum scelerisque. Phasellus rhoncus ultrices vestibulum. ",
                "image": "https://unistellaroptics.com/wp-content/uploads/2021/08/brilliant-star-clusters-challenge-768x432.png"
            },
            "sections": [
                {
                    "title": "title",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet lacus nibh. Nunc ultrices, sapien commodo tristique convallis, quam urna pulvinar lectus, a hendrerit lacus erat non turpis. Donec massa leo, vulputate vel tortor tempor, hendrerit bibendum diam. Integer lectus magna, placerat eget dolor ut, suscipit auctor neque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse laoreet in ante id luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; ",
                    "content": [{
                        "type": 0,
                        "url": "https://unistellaroptics.com/wp-content/uploads/2021/08/omega-centauri-john-pickering-600x450.jpg",
                        "legend": "caption of the image or video"
                    }],
                    "goto": {
                        "uid": "object UID",
                        "title": "button title"
                    }
                },
                {
                    "title": "title2",
                    "description": "2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet lacus nibh. Nunc ultrices, sapien commodo tristique convallis, quam urna pulvinar lectus, a hendrerit lacus erat non turpis. Donec massa leo, vulputate vel tortor tempor, hendrerit bibendum diam. Integer lectus magna, placerat eget dolor ut, suscipit auctor neque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse laoreet in ante id luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; ",
                    "content": [{
                        "type": 0,
                        "url": "https://cdn.futura-sciences.com/buildsv6/images/mediumoriginal/0/7/e/07e9e73246_50161111_nebuleuse-tarentule-unistellar.jpg",
                        "legend": "caption of the image or video"
                    }],
                    "goto": {
                        "uid": "object UID",
                        "title": "button title"
                    }
                },
                {
                    "title": "Conclusion",
                    "description": "2Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet lacus nibh. Nunc ultrices, sapien commodo tristique convallis, quam urna pulvinar lectus, a hendrerit lacus erat non turpis. Donec massa leo, vulputate vel tortor tempor, hendrerit bibendum diam. Integer lectus magna, placerat eget dolor ut, suscipit auctor neque. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse laoreet in ante id luctus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; ",
                    "content" : [],
                    "goto": {
                        "uid": "object UID",
                        "title": "button title"
                    }
                }
            ],
            "info": {
                "articleID": "stringID",
                "startDate": "start date of visibility",
                "endDate": "end date of visibility",
                "version": 1,
                "sharingUrl": "sharing URL",
                "keywords": ["keyword1", "keywords2"],
                "category": 0,
                "isDraft": true,
                "countryCode": "fr"
            }
        } */
    ],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_ARTICLE:
        return {
            ...state,
            currentArticle: action.currentArticle
        } 
    default:
      return state;
  }
};

export default reducer;