import { topicsList } from 'bo-config';

export class Notification {
  id = null;
  name = '';
  translations = [];
  segmentation = {};
  forceEnglish = false;
  updated = false;
  createdAt = '';
  updatedAt = '';

  constructor(object) {
    this.id = object.id;
    this.name = object.name;
    if (object instanceof Notification || object.segmentation) {
      this.translations = [
        ...object.translations.map(
          (translation) => new Translation(translation)
        ),
      ];
      this.segmentation = new Segmentation(object.segmentation);
    } else {
      if (object.translations?.items)
        this.translations = [
          ...object.translations.items.map((t) => new Translation(t)),
        ];
      else {
        this.translations = [
          ...object.translations.map((t) => new Translation(t)),
        ];
      }
      this.segmentation = new Segmentation(object.topics);
    }
    this.createdAt = object.createdAt || '';
    this.updatedAt = object.updatedAt || '';
    this.forceEnglish = object.forceEnglish || false;
    this.updated = object.updated || false;
    this.jobNotification = object.jobNotification || null;
    if (object.sendingRequests?.items)
      this.sendingRequests = [...object.sendingRequests.items.map((t) => t)];
  }

  isSendable() {
    return this.id && this.translations.length > 0 && this.checkValidity();
  }

  /*   isSent() {
    return this.translations.every((t) => t.sent);
  } */

  checkValidity() {
    if (!this.segmentation.checkValidity()) return false;
    for (const content of this.translations) {
      if (content.checkValidity()) continue;
      return false;
    }
    return true;
  }

  hasEnglish() {
    return this.translations.some((t) => t.lang === 'lg_en');
  }

  create() {
    return;
  }

  getSavableNotification() {
    return {
      ...(this.id && { id: this.id }),
      topics: this.segmentation.getTopicsAndDevice(),
      name: this.name,
      forceEnglish: this.forceEnglish,
    };
  }
}

export class Translation {
  id = null;
  lang = null;
  title = '';
  description = '';
  link = '';
  updated = false;

  constructor(object) {
    this.id = object.id;
    this.lang = object.lang;
    this.title = object.title;
    this.description = object.description;
    this.lang = object.lang;
    this.link = object.link;
    this.updated = object.updated;
  }

  checkValidity() {
    return (
      this.lang && this.title && this.description && this.checkLinkValidity()
    );
  }

  checkLinkValidity() {
    return this.link
      ? /^(https:\/\/|unistellar:\/\/).*/g.test(this.link)
      : true;
  }

  getSavableTranslation() {
    return {
      ...(this.id && { id: this.id }),
      lang: this.lang,
      title: this.title,
      description: this.description,
      link: this.link,
    };
  }
}

export class Segmentation {
  device = {
    iOS: false,
    android: false,
  };
  topics = [];

  /**
   * @param accept a Segmentation object or an array of topics
   */
  constructor(arg) {
    if (arg?.device) {
      this.device = { ...arg.device };
      if (arg?.topics) this.topics = [...arg.topics];
    } else {
      this.device =
        !arg.includes('ios') && !arg.includes('android')
          ? { iOS: true, android: true }
          : {
              iOS: arg.includes('ios'),
              android: arg.includes('android'),
            };
      this.topics = [
        ...arg.filter((topic) => topic !== 'ios' && topic !== 'android'),
      ];
    }
  }

  getTopicsAndDevice() {
    const topicsAndDeviceArray = [...this.topics]
      .concat(this.device.iOS ? 'ios' : [])
      .concat(this.device.android ? 'android' : []);
    return topicsAndDeviceArray;
  }

  checkValidity() {
    return (
      (this.device.iOS || this.device.android) &&
      this.topics.some((t) => topicsList.find((topic) => topic.value === t))
    );
  }
}
