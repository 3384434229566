import { SET_USER, RESET } from '../actions/app';
import { bo_id, permissions, countries, languages } from '../bo-config';

const initialState = {
  bo_id,
  permissions,
  countries,
  languages,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
