/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      topics
      name
      forceEnglish
      translations {
        nextToken
      }
      sendingRequests {
        nextToken
      }
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationJobNotificationId
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        translations {
          items {
            id
            title
            description
            link
            lang
          }
        }
        jobNotification {
          status
          updatedAt
        }
        sendingRequests {
          items {
            lang
            topics
            status
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getTranslation = /* GraphQL */ `
  query GetTranslation($id: ID!) {
    getTranslation(id: $id) {
      id
      title
      description
      link
      lang
      messageId
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      createdAt
      updatedAt
      notificationTranslationsId
    }
  }
`;
export const listTranslations = /* GraphQL */ `
  query ListTranslations(
    $id: ID
    $filter: ModelTranslationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTranslations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        description
        link
        lang
        messageId
        createdAt
        updatedAt
        notificationTranslationsId
      }
      nextToken
    }
  }
`;
export const getSendingRequest = /* GraphQL */ `
  query GetSendingRequest($id: ID!) {
    getSendingRequest(id: $id) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      topics
      lang
      status
      jobNotification {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      createdAt
      updatedAt
      notificationSendingRequestsId
      jobNotificationSendingRequestsId
      sendingRequestJobNotificationId
    }
  }
`;
export const listSendingRequests = /* GraphQL */ `
  query ListSendingRequests(
    $id: ID
    $filter: ModelSendingRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSendingRequests(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        topics
        lang
        status
        createdAt
        updatedAt
        notificationSendingRequestsId
        jobNotificationSendingRequestsId
        sendingRequestJobNotificationId
      }
      nextToken
    }
  }
`;
export const getJobNotification = /* GraphQL */ `
  query GetJobNotification($id: ID!) {
    getJobNotification(id: $id) {
      id
      notification {
        id
        topics
        name
        forceEnglish
        createdAt
        updatedAt
        notificationJobNotificationId
      }
      status
      sendingRequests {
        nextToken
      }
      createdAt
      updatedAt
      jobNotificationNotificationId
    }
  }
`;
export const listJobNotifications = /* GraphQL */ `
  query ListJobNotifications(
    $id: ID
    $filter: ModelJobNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        createdAt
        updatedAt
        jobNotificationNotificationId
      }
      nextToken
    }
  }
`;
