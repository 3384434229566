import { graphqlOperation } from 'aws-amplify';
import { listNotifications } from 'graphql/queries';
import { Notification, Segmentation, Translation } from 'Model/notification';
import {
  createNotification,
  createTranslation,
  deleteNotification,
  deleteTranslation,
  updateNotification,
  updateTranslation,
  sendNotification,
} from 'graphql/mutations';

export const SET_CURRENT_NOTIFICATION = 'SET_CURRENT_NOTIFICATION';
export const SET_CURRENT_CONTENT = 'SET_CURRENT_CONTENT';
export const SET_CURRENT_CONTENT_LANGUAGE = 'SET_CURRENT_CONTENT_LANGUAGE';
export const SET_CURRENT_CONTENT_INDEX = 'SET_CURRENT_CONTENT_INDEX';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SAVE_NOTIFICATION = 'SAVE_NOTIFICATION';
export const SAVE_TRANSLATION = 'SAVE_TRANSLATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_TRANSLATION = 'ADD_TRANSLATION';
export const SEND_TEST_NOTIFICATION = 'SEND_TEST_NOTIFICATION';

export const SET_IS_SAVING = 'SET_IS_SAVING';
export const SET_IS_SENDING = 'SET_IS_SENDING';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_MESSAGE = 'SET_MESSAGE';
export const RESET_MESSAGE = 'RESET_MESSAGE';

export const SET_NEXT_TOKEN = 'SET_NEXT_TOKEN';

export const setCurrentNotification = (notification) => ({
  type: SET_CURRENT_NOTIFICATION,
  currentNotification: notification,
});

export const setCurrentContent = (content) => ({
  type: SET_CURRENT_CONTENT,
  currentContent: content,
});

export const setCurrentContentLanguage = (language) => ({
  type: SET_CURRENT_CONTENT_LANGUAGE,
  language,
});

export const setCurrentContentIndex = (index) => ({
  type: SET_CURRENT_CONTENT_INDEX,
  index,
});

export const setNotifications = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
};

export const setNextToken = (nextToken) => ({
  type: SET_NEXT_TOKEN,
  nextToken,
});

export const addNotification = (notification) => ({
  type: ADD_NOTIFICATION,
  notification,
});

export const addTranslation = (language) => ({
  type: ADD_TRANSLATION,
  language,
});

export const sendTestNotification =
  (notificationId) => async (dispatch, getState, services) => {
    try {
      dispatch(setIsSending(true));

      const response = await services.API.graphql(
        graphqlOperation(sendNotification, {
          notificationId,
          testing: true,
        })
      );
      if (response.errors)
        dispatch(
          setMessage({ type: 'error', text: response.errors[0].message })
        );
      else
        dispatch(
          setMessage({ type: 'success', text: 'Sending in progress...' })
        );
      dispatch(setIsSending(false));
    } catch (error) {
      console.log(error);
      dispatch(
        setMessage({
          type: 'error',
          text: error.message || error.errors[0].message,
        })
      );
      dispatch(setIsSending(false));
    }
  };

export const sendFCMNotification =
  (notificationId) => async (dispatch, getState, services) => {
    dispatch(setIsSending(true));
    try {
      const response = await services.API.graphql(
        graphqlOperation(sendNotification, {
          notificationId,
        })
      );
      if (response.errors /* TODO: handle errors */)
        dispatch(
          setMessage({ type: 'error', text: response.errors[0].message })
        );
      else
        dispatch(
          setMessage({ type: 'success', text: 'Your notification is queued' })
        );
      dispatch(setIsSending(false));
    } catch (error) {
      console.log(error);
      dispatch(
        setMessage({
          type: 'error',
          text: error.message || error.errors[0].message,
        })
      );
      dispatch(setIsSending(false));
    }
  };

export const setIsSaving = (payload) => ({
  type: SET_IS_SAVING,
  payload,
});

export const setIsSending = (payload) => ({
  type: SET_IS_SENDING,
  payload,
});

export const setIsLoading = (payload) => ({
  type: SET_IS_LOADING,
  payload,
});

export const setMessage = (message) => ({
  type: SET_MESSAGE,
  message,
});

export const resetMessage = () => ({
  type: RESET_MESSAGE,
});

export const saveNotification = () => async (dispatch, getState, services) => {
  const state = getState();
  const currentNotification = state.notifications.currentNotification;
  let newNotification = { ...currentNotification };
  let newTranslations = [...currentNotification.translations];
  const newNotifications = [...state.notifications.notifications];

  try {
    if (currentNotification.id) {
      // if notification is already in DB
      if (currentNotification.updated) {
        const response = await services.API.graphql(
          graphqlOperation(updateNotification, {
            input: new Notification(
              currentNotification
            ).getSavableNotification(),
          })
        );
        if (response.errors) throw new Error(response.errors[0].message);
        newNotification = response.data.updateNotification;
      }
      const currentNotificationIndex =
        state.notifications.notifications.findIndex(
          (notification) => notification.id === currentNotification.id
        );
      newTranslations = await saveTranslations(currentNotification, services);
      newNotifications.splice(currentNotificationIndex, 1, {
        ...new Notification({
          ...newNotification,
          segmentation:
            newNotification.segmentation ||
            new Segmentation(newNotification.topics),
          translations: newTranslations,
        }),
      });
      dispatch(
        setCurrentNotification({
          ...currentNotification,
          translations: [...newTranslations],
          id: newNotification.id,
          updated: false,
        })
      );
      dispatch(setNotifications(newNotifications));
    } else {
      // New notification to save
      const response = await services.API.graphql(
        graphqlOperation(createNotification, {
          input: new Notification(currentNotification).getSavableNotification(),
        })
      );
      if (response.errors) throw new Error(response.errors[0].message);
      newTranslations = await saveTranslations(
        {
          ...currentNotification,
          id: response.data.createNotification.id,
        },
        services
      );
      const newNotification = new Notification({
        ...response.data.createNotification,
        segmentation: new Segmentation(response.data.createNotification.topics),
        translations: newTranslations,
      });
      dispatch(
        setCurrentNotification({
          ...currentNotification,
          translations: [...newTranslations],
          id: newNotification.id,
          updated: false,
          createdAt: newNotification.createdAt,
        })
      );
      dispatch(
        addNotification({
          ...state.notifications.currentNotification,
          translations: [...newTranslations],
          id: newNotification.id,
          createdAt: newNotification.createdAt,
        })
      );
    }
    dispatch(setMessage({ type: 'success', text: 'Notification saved' }));
    dispatch(setIsLoading(true));
  } catch (error) {
    console.log(error);
    dispatch(setMessage({ type: 'error', text: 'Error while saving' }));
  }
};

export const saveTranslations = async (notification, services) => {
  const newTranslations = [];
  for (const [index, translation] of notification.translations.entries()) {
    if (translation.updated) {
      const newTranslation = await saveTranslation(
        translation,
        notification.id,
        index,
        services
      );
      newTranslations.push(newTranslation);
    } else {
      newTranslations.push(translation);
    }
  }
  return Promise.all(newTranslations);
};

export const saveTranslation = async (
  translation,
  idNotification,
  index,
  services
) => {
  if (translation.id) {
    // if translation is already in DB
    const updatedTranslation = await services.API.graphql(
      graphqlOperation(updateTranslation, {
        input: {
          ...new Translation(translation).getSavableTranslation(),
          notificationTranslationsId: idNotification,
        },
      })
    );
    if (updatedTranslation.errors)
      throw new Error(updatedTranslation.errors[0].message);
    return updatedTranslation.data.updateTranslation;
  } else {
    // if new Translation to save
    const newTranslation = await services.API.graphql(
      graphqlOperation(createTranslation, {
        input: {
          ...new Translation(translation).getSavableTranslation(),
          notificationTranslationsId: idNotification,
        },
      })
    );
    if (newTranslation.errors)
      throw new Error(newTranslation.errors[0].message);
    return newTranslation.data.createTranslation;
  }
};

export const removeNotification =
  (idNotification) => async (dispatch, getState, services) => {
    try {
      const notification = getState().notifications.notifications.find(
        (notification) => notification.id === idNotification
      );
      for (const translation of notification.translations) {
        const response = await services.API.graphql(
          graphqlOperation(deleteTranslation, {
            input: {
              id: translation.id,
            },
          })
        );
        if (response.errors) throw new Error(response.errors[0].message);
      }
      const response = await services.API.graphql(
        graphqlOperation(deleteNotification, {
          input: {
            id: idNotification,
          },
        })
      );
      if (response.errors) throw new Error(response.errors[0].message);
      const newNotifications = [
        ...getState().notifications.notifications,
      ].filter((notification) => notification.id !== idNotification);
      dispatch(setNotifications(newNotifications));

      dispatch(setMessage({ type: 'success', text: 'Notification removed' }));
    } catch (error) {
      console.log(error);
      dispatch(setMessage({ type: 'error', text: 'Error during suppression' }));
    }
  };

export const removeTranslation =
  (indexToRemove) => async (dispatch, getState, services) => {
    const translation =
      getState().notifications.currentNotification.translations[indexToRemove];
    try {
      if (translation.id) {
        const response = await services.API.graphql(
          graphqlOperation(deleteTranslation, {
            input: {
              id: translation.id,
            },
          })
        );
        if (response.errors) throw new Error(response.errors[0].message);
      }
      const newTranslations = [
        ...getState().notifications.currentNotification.translations,
      ].filter((translation, index) => index !== indexToRemove);

      if (
        indexToRemove <= getState().notifications.currentContent.index &&
        getState().notifications.currentContent.index !== 0
      ) {
        dispatch(
          setCurrentContentIndex(
            getState().notifications.currentContent.index - 1
          )
        );
      }
      dispatch(
        setCurrentNotification({
          ...getState().notifications.currentNotification,
          translations: newTranslations,
        })
      );

      dispatch(setMessage({ type: 'success', text: 'Translation removed' }));
      dispatch(setIsLoading(true));
    } catch (error) {
      console.log(error);
      dispatch(setMessage({ type: 'error', text: 'Error during suppression' }));
    }
  };

export const fetchNotifications =
  (next) => async (dispatch, getState, services) => {
    try {
      const response = await services.API.graphql(
        graphqlOperation(listNotifications, {
          nextToken: next ? getState().notifications.nextToken : null,
        })
      );
      if (response.errors) throw new Error(response.errors[0].message);

      response.data.listNotifications.items.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
      dispatch(
        setNotifications(
          next
            ? [
                ...getState().notifications.notifications,
                ...response.data.listNotifications.items.map(
                  (item) => new Notification(item)
                ),
              ]
            : response.data.listNotifications.items.map(
                (item) => new Notification(item)
              )
        )
      );
      dispatch(setNextToken(response.data.listNotifications.nextToken));
    } catch (error) {
      console.log(error);
      dispatch(setMessage({ type: 'error', text: 'Error while loading' }));
    }
  };
