import { API, graphqlOperation } from 'aws-amplify';
import { createNotification, createTranslation } from 'graphql/mutations';
import {
  setCurrentNotification,
  setNotifications,
  addNotification,
  setCurrentContent,
} from '../actions/notifications';
import { Notification, Translation } from 'Model/notification';
/*import { API, Auth } from 'aws-amplify';
 */

/* const apiName = '**************';
const getMyInit = async (queryStringParameters,body) => {
    const myInit = { 
        headers: {
        'Content-Type' : 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    if (body) {
        myInit.body = body;
    } else {
        myInit.queryStringParameters = queryStringParameters;
    }
    return myInit;
}
 */

const notificationsReducer = (store) => (next) => async (action) => {
  const state = store.getState();
  //let queryStringParameters = {};
  //let body = {};
  //let nextToken = '';

  try {
    switch (action.type) {
      case 'SAVE_NOTIFICATION':
        /* if (state.notifications.currentNotification.id) {
          // TODO Call API for UPDATE
          const currentNotificationIndex =
            state.notifications.notifications.findIndex(
              (notification) =>
                notification.id === state.notifications.currentNotification.id
            );
          const newNotifications = [...state.notifications.notifications];
          newNotifications.splice(
            currentNotificationIndex,
            1,
            state.notifications.currentNotification
          );
          await store.dispatch(setNotifications(newNotifications));
        } else {
          const newNotification = await API.graphql(
            graphqlOperation(createNotification, {
              input: new Notification(
                state.notifications.currentNotification
              ).getSavableNotification(),
            })
          ); */
        /*  const newTranslation = await API.graphql(
            graphqlOperation(createTranslation, {
              input: {
                ...new Translation(
                  state.notifications.currentNotification.translations[0]
                ).getSavableTranslation(),
                notificationTranslationsId:
                  newNotification.data.createNotification.id,
              },
            })
          ); */
        /* await store.dispatch(
            setCurrentNotification({
              ...state.notifications.currentNotification,
              id: newNotification.data.createNotification.id,
            })
          );
          await store.dispatch(
            addNotification({
              ...state.notifications.currentNotification,
              id: newNotification.data.createNotification.id,
            })
          ); */
        /*           await store.dispatch(
            setCurrentContent({
              ...state.notifications.currentContent,
              id: newTranslation.data.createTranslation.id,
            })
          ); */
        /* } */
        break;
      /* case 'SAVE_TRANSLATION':
        if (state.notifications.currentContent.id) {
          // TODO Call API for UPDATE

          //await store.dispatch(setTranslation(updatedContent));
        } else {
          const newTranslation = await API.graphql(
            graphqlOperation(createTranslation, {
              input: {
                ...new Translation(
                  state.notifications.currentNotification.translations[0]
                ).getSavableTranslation(),
                notificationTranslationsId:
                  newNotification.data.createNotification.id,
              },
            })
          );
          await store.dispatch(
            setCurrentNotification({
              ...state.notifications.currentNotification,
              id: newNotification.data.createNotification.id,
            })
          );
          await store.dispatch(
            addNotification({
              ...state.notifications.currentNotification,
              id: newNotification.data.createNotification.id,
            })
          );
          await store.dispatch(
            setCurrentContent({
              ...state.notifications.currentContent,
              id: newTranslation.data.createTranslation.id,
            })
          );
        }
        break; */
      default:
    }
    next(action);
  } catch (error) {
    console.log(error);
  }
};

export default notificationsReducer;
