import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchField, Loader } from '@aws-amplify/ui-react';
import { fetchUser, setSearching } from '../../actions/users';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ReportIcon from '@mui/icons-material/Report';
import { formatDate } from '../../modules/date';
import UserGroupManager from './UserGroupManager';

import './userManager.scss';
import { Breadcrumb } from 'back-office-common';
import { bo_id } from 'bo-config';

const UserManager = (props) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const { user, isSearching } = useSelector((state) => state.users);

  const handleSubmit = (value) => {
    if (/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g.test(search) && search.length > 5) {
      dispatch(setSearching(true));
      dispatch(fetchUser(value));
      setSubmitted(true);
    }
  };

  return (
    <div className="content">
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '900px',
          justifyContent: 'start',
        }}
      >
        <Breadcrumb
          path={[
            { text: 'Members', link: '/members/' + bo_id },
            { text: 'Add Member' },
          ]}
        />
      </div>
      <div className="search-form">
        <SearchField
          type="email"
          hasError={
            !/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g.test(search) &&
            search.length > 5
          }
          label={`Search for a user by email`}
          labelHidden={false}
          placeholder="john@doe.acme"
          errorMessage="Please enter a valid email address"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          onSubmit={handleSubmit}
          onClear={() => {
            setSearch('');
            setSubmitted(false);
          }}
        ></SearchField>
      </div>
      {isSearching && <Loader />}
      {!isSearching && submitted && !user && <p>No results</p>}
      {user && (
        <article key={user.Username} className="user-card">
          <header className="user-header user-infos">
            <AccountCircleIcon fontSize="default" />
            <div className="user-email violet">
              {user.email}
              {user.email_verified === 'true' /*eslint-disable-line*/ ? (
                <span title="Verified email">
                  <VerifiedUserIcon fontSize="small" />
                </span>
              ) : (
                <span title="Not verified email">
                  <ReportIcon fontSize="small" />
                </span>
              )}
            </div>
          </header>
          <main className="user-content">
            <div className="user-infos">
              <span className="user-status">
                Status:<span className="value">{user.UserStatus}</span>
              </span>
              <span className="user-creation">
                Creation:
                <span className="value">{formatDate(user.createdAt)}</span>
              </span>
            </div>
            <div className="user-infos">
              <span className="user-enabled">
                Enabled:<span className="value">{user.Enabled + ''}</span>
              </span>
              <span className="user-creation">
                Last update:
                <span className="value">{user.updatedAt}</span>
              </span>
            </div>
          </main>
          <footer className="user-footer">
            <UserGroupManager></UserGroupManager>
          </footer>
        </article>
      )}
    </div>
  );
};

UserManager.propTypes = {};

export default UserManager;
